:root {
	--darkGray: rgb(45, 45, 45);
	--lightGray: rgb(96, 96, 96);
	--lightLightGray: rgb(143, 143, 143);
	--darkWhite: rgb(197, 197, 197);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
	overflow-x: hidden;
	/*hides anything that is off the page horizontally. parent element of slid-off element must have position: relative */
}

body {
	background-color: var(--lightGray);
}

a {
	text-decoration: none;
	color: white;
}

select {
	padding: 0.25rem;
}

button {
	padding: 0.25rem;
}

/* 'Add' button */
input[type='button'] {
	padding: 0.25rem;
}

input[type='text'] {
	height: 1.6rem;
}

h1 {
	margin-bottom: 0.5rem;
	color: white;
}

/* ##################################################### */
/* SORTING AREA */
/* ##################################################### */
#sortByDropdown {
	margin-left: 1rem;
}

#sortByDropdownLabel {
	color: white;
	display: inline;
}

#searchByLabel {
	color: white;
	display: inline;
	margin-left: 3rem;
}

#searchByText {
	width: 10rem;
	margin-left: 1rem;
}

#searchByTextButton {
	margin-left: 1rem;
}

#lineBreaker {
	display: none;
}

#lineBreaker2 {
	display: none;
}

#clearResultsButton {
	margin-left: 0.5rem;
}

#searchByLabelMediaQuery {
	color: white;
	display: inline;
	display: none;
}

/* ##################################################### */
/* ADD ROW */
/* ##################################################### */
#inputItemDescription {
	width: 20rem;
}

/* ##################################################### */
/* TABLES */
/* ##################################################### */
#itemsTable {
	margin-top: 0.5rem;
}

table {
	border: 1px solid black;
	width: 60rem;
}

td {
	padding: 0.5rem;
	/* padding-left: 0.5rem; */
	/* padding-right: 0.5rem; */
	text-align: center;
}

th {
	background-color: aqua;
	padding: 0.5rem;
}

table tr:nth-child(odd) {
	background-color: var(--darkWhite);
}

table tr:nth-child(even) {
	background-color: var(--lightLightGray);
}
table th:nth-child(6) {
	width: 4rem;
}
table td:nth-child(6) {
	width: 4rem;
}
table th:nth-child(5) {
	width: 22rem;
}
table td:nth-child(5) {
	width: 22rem;
}
table th:nth-child(1) {
	width: 12rem;
}
table td:nth-child(1) {
	width: 12rem;
}

/* /////////////////////////////////// */
/* NAV BAR */

nav {
	background-color: var(--darkGray);
	color: white;
	display: flex;
	flex-direction: row;
	padding: 1rem;
	align-items: center;
	padding-top: 1.5rem;
}

.navSpacer {
	width: 2rem;
}

nav h1 {
	background-color: var(--lightGray);
	padding: 1rem;
}

#appTitle {
	background-color: aqua;
	color: var(--darkGray);
}

.navLink {
	font-size: 1.5rem;
	border-bottom: 3px solid var(--darkGray);
	padding-bottom: 0.4rem;
}

.navLink:hover {
	border-bottom: 3px solid aqua;
	color: aqua;
	transition: all 0.25s;
}

/* /////////////////////////////////// */
/* Pages */

#main {
	background-color: var(--lightGray);
	height: 100%;
	padding-left: 2rem;
	padding-top: 2rem;
}
