@media (max-width: 1020px) {
	table {
		width: 50rem;
	}

	#inputItemDescription {
		width: 13rem;
	}
}
/* ############################################ */
/* Mobile NAV */
/* ############################################ */
@media (max-width: 860px) {
	table {
		/* width: 40rem; */
		width: 98%;
		margin: auto;
	}
	#main {
		padding-left: 0;
	}
	.tableHeaders {
		margin-left: 1rem;
	}

	#inputItemDescription {
		width: 8rem;
	}

	#inputItemName {
		width: 5rem;
	}
	/* #searchByLabel {
		margin-left: 1rem;
	} */
}

@media (max-width: 775px) {
	#appTitle {
		display: none;
	}

	nav {
		padding-top: 2rem;
	}
}
@media (max-width: 722px) {
	#lineBreaker {
		display: inline;
	}
	#searchByLabel {
		margin-left: 0;
	}
	#searchByText {
		margin-top: 0.5rem;
	}
}

@media (max-width: 636px) {
	/* hide Description column */
	.descriptionColumn {
		display: none;
	}
	#inputItemName {
		width: 8rem;
	}
}

@media (max-width: 520px) {
	#inputItemName {
		width: 7rem;
	}
}

@media (max-width: 505px) {
	/* Hide owner column */
	.ownerColumn {
		display: none;
	}
}

@media (max-width: 475px) {
	/* decrease search by item name */
	#searchByLabel {
		display: none;
	}
	#searchByLabelMediaQuery {
		display: inline;
	}
	#searchByText {
		margin-left: 0.25rem;
	}
	.navLink {
		font-size: 1.25rem;
	}
	#firstSpacer {
		display: none;
	}
	#sortByDropdown {
		margin-left: 0.25rem;
	}
	#searchByTextButton {
		margin-left: 0.5rem;
	}
}

@media (max-width: 400px) {
	/* decrease search by item name */
	.categoryColumn {
		display: none;
	}
	#lineBreaker2 {
		display: inline;
	}
	#searchByTextButton {
		margin-top: 0.5rem;
		margin-left: 0;
	}
}
