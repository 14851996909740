:root {
	--darkGray: rgb(45, 45, 45);
	--lightGray: rgb(96, 96, 96);
	--lightLightGray: rgb(143, 143, 143);
	--darkWhite: rgb(197, 197, 197);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
	overflow-x: hidden;
	/*hides anything that is off the page horizontally. parent element of slid-off element must have position: relative */
}

#loginParent {
	width: 20rem;
	height: 10rem;
	border-radius: 5px;
	border: 2px solid white;
	background-color: var(--darkGray);
	margin: auto;
	margin-top: 4rem;
	position: relative;
}

#loginHeader {
	height: 2rem;
	background-color: aqua;
	display: flex;
	align-items: center;
	justify-content: center;
}

#loginTitle {
	width: fit-content;
}

#loginBody {
	width: fit-content;
	margin: 2rem auto 0;
}
form {
	width: fit-content;
}

#formSubmitButton {
	margin-left: 1rem;
}

#passwordInput {
	height: 1.65rem;
}

#hideshow {
	margin-top: 0.25rem;
	background: var(--darkGray);
	border: none;
	color: white;
	cursor: pointer;
}

.loginStatus {
	background-color: red;
	width: 100%;
	color: white;
	position: absolute;
	bottom: 0;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
}

.showError {
	/* opacity: 1; */
	animation-name: fadeOut;
	animation-duration: 1s;
}

@keyframes fadeOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

#loginStatus span {
	margin: auto;
}
